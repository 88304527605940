<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.837" x2="0" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_lesrooster" transform="translate(-390 -2769)">
    <g data-name="Group 421" id="Group_421" transform="translate(15 -4)">
     <rect :fill="background" data-name="Rectangle 194" height="183" id="Rectangle_194" transform="translate(375 2773)" width="183"/>
     <path d="M88.889,87.389V21.753a9.384,9.384,0,0,0-9.377-9.377H70.136V3H60.759v9.377H32.63V3H23.253v9.377H13.877A9.384,9.384,0,0,0,4.5,21.753V87.389a9.384,9.384,0,0,0,9.377,9.377H79.512A9.384,9.384,0,0,0,88.889,87.389ZM32.63,78.012H23.253V68.636H32.63Zm0-18.753H23.253V49.883H32.63ZM51.383,78.012H42.006V68.636h9.377Zm0-18.753H42.006V49.883h9.377ZM70.136,78.012H60.759V68.636h9.377Zm0-18.753H60.759V49.883h9.377Zm9.377-23.441H13.877V26.441H79.512Z" fill="url(#linear-gradient)" id="bxs-calendar" transform="translate(419.806 2814.617)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>